import React, { useState } from 'react';

function Tooltip({ text, style, children }) {
    const [show, setShow] = useState(false);

    const handleMouseEnter = () => setShow(true);
    const handleMouseLeave = () => setShow(false);

    return (
        <div 
            className="tooltip-parent" 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave}
        >
            {children}
            {show && (
                <div className="tooltip">
                    <span>{text}</span>
                </div>
            )}
        </div>
    );
}

export default Tooltip;
