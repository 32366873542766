import { jwtDecode } from 'jwt-decode';
import { auth, db } from './firebase';
import { ref, get, update } from 'firebase/database';
import { useCookies } from 'react-cookie';
import { isUsernameUnique } from './firebase';

// Import utils line
// import { fetchUserIdFromToken, fetchUsernameFromID, fetchdisplaynameFromID, fetchBioFromID, updatedisplayname, updateUserNsame, updateBio, isUserLoggedIn, isValidUsername, isValiddisplayname, isValidPassword } from '../utils';

export const fetchUserIdFromToken = (token) => {
  try {
    const decoded = jwtDecode(token);
    return decoded.user_id;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

export const fetchUsernameFromID = (UID) => {
  const usernameRef = ref(db, `users/${UID}/username`);
  return get(usernameRef).then((snapshot) => snapshot.val());
}

export const fetchdisplaynameFromID = (UID) => {
  const displaynameRef = ref(db, `users/${UID}/displayname`);
  return get(displaynameRef).then((snapshot) => snapshot.val());
}

export const fetchBioFromID = (UID) => {
  const bioRef = ref(db, `users/${UID}/bio`);
  return get(bioRef).then((snapshot) => snapshot.val());
}

export const updatedisplayname = (UID, content) => {
  const displaynameRef = ref(db, `users/${UID}`);
  return update(displaynameRef, { displayname: content });
}
export async function fetchPhotoURLFromID(userId) {
  try {
    const userRef = ref(db, `users/${userId}/photoURL`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      return '/default-profile.png'; // Default profile picture URL if not found
    }
  } catch (error) {
    console.error('Error fetching photo URL:', error);
    return '/default-profile.png'; // Default profile picture URL on error
  }
}
export const updateUsername = (UID, content) => {
  const usernameRef = ref(db, `users/${UID}`);
  return update(usernameRef, { username: content });
}

export const updateBio = (UID, content) => {
  const bioRef = ref(db, `users/${UID}`);
  return update(bioRef, { bio: content });
}

export const isValiddisplayname = (displayname) => {
  const regex = /^[a-zA-Z0-9_-]{1,32}$/;
  return regex.test(displayname);
}
export const isValidUserName = async (username) => {
  const regex = /^[a-zA-Z0-9_-]{3,32}$/;
  console.log(username);

  // Await the result of isUsernameUnique if it's asynchronous
  const unique = await isUsernameUnique(username);
  
  if (!unique) {
    console.log("username used");
    return false;
  }
  
  return regex.test(username);
}

export const isValidPassword = (password) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
  return regex.test(password);
}

export const getLocalID = () => {

}