import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ref, get } from 'firebase/database';
import { db } from '../firebase';
import { fetchUserIdFromToken } from '../utils';
import { useCookies } from 'react-cookie';
import CreateChatButton from "../components/createchat";

const ChatList = ({ chats }) => {
  const { chatId } = useParams();
  const [tab, setTab] = useState(1);
  const [filteredChats, setFilteredChats] = useState([]);
  const [cookies] = useCookies(['userToken']);

  useEffect(() => {
    const fetchUserChats = async () => {
      const userId = await fetchUserIdFromToken(cookies.userToken);
      if (!userId) return;

      const memberChats = [];
      for (const chat of chats) {
        const chatRef = ref(db, `chats/${chat.id}`);
        const snapshot = await get(chatRef);
        if (snapshot.exists()) {
          const chatData = snapshot.val();
          const isMember = chatData.members?.includes(userId) || chatData.owner === userId;
          if (isMember) {
            memberChats.push(chat);
          }
        }
      }
      setFilteredChats(memberChats);
    };

    fetchUserChats();
  }, [chats, cookies.userToken]);

  return (
    <div>
      <div className='chat-selector'>
        <div className='chat-selector-communities'>
          <span 
            className="material-symbols-outlined"
            onClick={() => setTab(0)}
          >
            communities
          </span>
          <div className={tab === 0 ? 'chat-selector-underline chat-selector-underline-active' : 'chat-selector-underline'}></div>
        </div>
        <div className='chat-selector-chats'>
          <span 
            className="material-symbols-outlined"
            onClick={() => setTab(1)}
          >
            person
          </span>
          <div className={tab === 1 ? 'chat-selector-underline chat-selector-underline-active' : 'chat-selector-underline'}></div>
        </div>
      </div>

      <CreateChatButton />

      {tab === 0 ? 
        <ul className='chat-list'>
          {filteredChats.map(chat => 
            chat.type === 'Community' ? (
              <Link to={`/app/${chat.id}`} key={chat.id} className={chat.id === chatId ? 'current-chat' : ''}>
                <li>{chat.name}</li>
              </Link>
            ) : null
          )}
        </ul>
        :
        <ul className='chat-list'>
          {filteredChats.map(chat => 
            chat.type === 'Chat' ? (
              <Link to={`/app/${chat.id}`} key={chat.id} className={chat.id === chatId ? 'current-chat' : ''}>
                <li>{chat.name}</li>
              </Link>
            ) : null
          )}
        </ul>
      }
    </div>
  );
};

export default ChatList;
