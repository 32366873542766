import { useState } from "react";
import { db } from "../firebase";
import { ref, push } from "firebase/database";
import { fetchUserIdFromToken } from "../utils"; // Ensure this utility is available to fetch the user ID
import { useCookies } from "react-cookie";

function CreateChatModal({ onClose }) {
  const [chatName, setChatName] = useState("");
  const [cookies] = useCookies(["userToken"]);
  const [nameStatus, setNameStatus] = useState(false);
  const [chatType, setChatType] = useState("Chat"); // Default to 'Chat'
  const userToken = cookies.userToken;

  const handleCreateChat = async () => {
    if (!chatName.trim()) return;
    if (chatName.trim().length > 32) return;
    try {
      const userId = await fetchUserIdFromToken(userToken);

      if (!userId) {
        console.error("User ID not found. Unable to create chat.");
        return;
      }

      const createChat = (chatName, ownerId) => {
        const chatsRef = ref(db, "chats");
        const newChat = {
          name: chatName,
          type: chatType,
          users: [],
          messages: {},
          owner: ownerId,
        };
        push(chatsRef, newChat);
      };

      createChat(chatName, userId);
      onClose();
    } catch (error) {
      console.error("Error creating chat:", error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <header>Chat Name</header>
        <input
          type="text"
          placeholder="Enter chat name..."
          value={chatName}
          onChange={(e) => setChatName(e.target.value)}
        />

        <header>Chat Type</header>
        <div className="create-chat-type-buttons">
          <button
            className={chatType === "Chat" ? "selected" : ""}
            onClick={() => setChatType("Chat")}
          >
            Chat
          </button>
          <button
            className={chatType === "Community" ? "selected" : ""}
            onClick={() => setChatType("Community")}
          >
            Community
          </button>
        </div>

        <div className="create-chat-final-buttons">
          <button onClick={handleCreateChat}>Create</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default CreateChatModal;
