import { useState } from "react";
import CreateChatModal from "./modal";
import Tooltip from "./tooltip";


function ChatList() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    
    <div className="create-chat-button">
      <Tooltip text="Create chat">
      <button className="create-chat-button-button" onClick={handleOpenModal}><span className="material-symbols-outlined chat-icon">add</span></button>
      </Tooltip>
      {isModalOpen && <CreateChatModal onClose={handleCloseModal} />}
    </div>
  );
}

export default ChatList;