import React, { useState, useEffect, useCallback } from 'react';
import { ref, push, get, onValue, off, remove } from 'firebase/database';
import { db } from '../firebase';
import { fetchUserIdFromToken, fetchdisplaynameFromID } from '../utils';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { formatRelative, format } from 'date-fns';
import ProfileModal from './profilepopout';
import Tooltip from './tooltip';
import defaultIcon from '../images/icons/user.svg';

const CACHE_VERSION = '1.0';

function addMessage(chatId, senderId, messageText) {
  if (!senderId) {
    console.error('Sender ID is undefined!');
    return Promise.reject('Sender ID is undefined');
  }

  const messagesRef = ref(db, `chats/${chatId}/messages`);
  const newMessage = {
    sender: senderId,
    text: messageText,
    timestamp: Date.now(),
  };

  return push(messagesRef, newMessage)
    .then(() => true)
    .catch((error) => {
      console.error('Error pushing message:', error);
      return false;
    });
}

function Chat({ chatId }) {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [cookies] = useCookies(['userToken']);
  const userToken = cookies.userToken;
  const [messagesMap, setMessagesMap] = useState({});
  const [messageText, setMessageText] = useState('');
  const [isMember, setIsMember] = useState(false);
  const [hoveredMessage, setHoveredMessage] = useState(null);
  const [chatOwner, setChatOwner] = useState(null);
  const [chatData, setChatData] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null); // State to manage the selected user
  const [showModal, setShowModal] = useState(false); // State to control the visibility of the modal

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userId = await fetchUserIdFromToken(userToken);
        if (!userId) {
          navigate('/login');
          return;
        }

        const userRef = ref(db, `users/${userId}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          setProfile({ ...snapshot.val(), uid: userId });
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, [userToken, navigate]);

  useEffect(() => {
    if (chatId) {
      const checkMembership = async () => {
        try {
          const chatRef = ref(db, `chats/${chatId}`);
          const snapshot = await get(chatRef);
          if (snapshot.exists()) {
            const data = snapshot.val();
            const members = data.members || [];
            const isOwner = data.owner === profile?.uid;
            setChatOwner(data.owner);
            setIsMember(members.includes(profile?.uid) || isOwner);
            setChatData(data);
          }
        } catch (error) {
          console.error('Error checking membership:', error);
        }
      };

      checkMembership();
    }
  }, [chatId, profile?.uid]);

  useEffect(() => {
    if (chatId && isMember) {
      const cachedMessages = sessionStorage.getItem(`chat_${chatId}_messages`);
      const cachedVersion = sessionStorage.getItem('chat_cache_version');
      
      if (cachedMessages && cachedVersion === CACHE_VERSION) {
        setMessagesMap(prevMap => ({
          ...prevMap,
          [chatId]: JSON.parse(cachedMessages)
        }));
      }

      const messagesRef = ref(db, `chats/${chatId}/messages`);
      const handleMessages = async (snapshot) => {
        if (snapshot.exists()) {
          const messagesArray = Object.entries(snapshot.val()).map(([id, msg]) => ({ id, ...msg }));
          const messagesWithUserInfo = await Promise.all(
            messagesArray.map(async (message) => {
              const username = await fetchdisplaynameFromID(message.sender);
              const userRef = ref(db, `users/${message.sender}`);
              const userSnapshot = await get(userRef);
              const user = userSnapshot.val();
              return {
                ...message,
                senderUsername: username,
                senderPhotoURL: user?.photoURL || defaultIcon,
              };
            })
          );
          
          setMessagesMap(prevMap => ({
            ...prevMap,
            [chatId]: messagesWithUserInfo
          }));

          sessionStorage.setItem(`chat_${chatId}_messages`, JSON.stringify(messagesWithUserInfo));
          sessionStorage.setItem('chat_cache_version', CACHE_VERSION);
        } else {
          setMessagesMap(prevMap => ({
            ...prevMap,
            [chatId]: []
          }));
        }
      };

      const listener = onValue(messagesRef, handleMessages);

      return () => off(messagesRef, 'value', listener);
    }
  }, [chatId, isMember]);

  const handleSendMessage = useCallback(() => {
    if (messageText.trim() !== '' && profile && profile.uid) {
      const tempMessage = {
        id: Date.now().toString(),
        sender: profile.uid,
        senderUsername: profile.displayname || profile.username || 'Unknown User',
        text: messageText,
        timestamp: Date.now(),
        sending: true,
      };

      setMessagesMap(prevMap => ({
        ...prevMap,
        [chatId]: [...(prevMap[chatId] || []), tempMessage]
      }));

      addMessage(chatId, profile.uid, messageText)
        .then((success) => {
          if (success) {
            setMessagesMap(prevMap => ({
              ...prevMap,
              [chatId]: prevMap[chatId].map(msg =>
                msg.id === tempMessage.id ? { ...msg, sending: false } : msg
              )
            }));
          }
        });

      setMessageText('');
    } else {
      console.error('Message text is empty or user is not available.');
    }
  }, [messageText, profile, chatId]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const formatTimestamp = (timestamp) => {
    if (isNaN(timestamp) || timestamp <= 0) {
      return 'Invalid time';
    }
    
    const now = new Date();
    const messageDate = new Date(timestamp);
    
    if (isNaN(messageDate.getTime())) {
      return 'Invalid time';
    }
  
    const relativeDate = formatRelative(messageDate, now);
    if (relativeDate.startsWith('today')) {
      return `Today at ${format(messageDate, 'h:mm a')}`;
    } else if (relativeDate.startsWith('yesterday')) {
      return `Yesterday at ${format(messageDate, 'h:mm a')}`;
    } else {
      return format(messageDate, 'M/d/yy h:mm a');
    }
  };
  const closeModal = () => {
    setShowModal(false);
    setSelectedUser(null);
  };
  const handleDeleteMessage = (messageId) => {
    const messageRef = ref(db, `chats/${chatId}/messages/${messageId}`);
    remove(messageRef).catch((error) => {
      console.error('Error deleting message:', error);
    });
  };

  const handleReplyMessage = (message) => {
    setMessageText(`@${message.senderUsername} `);
  };

  if (!profile) {
    return <div>Loading profile...</div>;
  }

  if (!isMember) {
    return (
      <div className='channel-banner'>
        {chatData?.type === 'Community' ? (
          <div>
            <h1>Welcome to<br></br>{chatData.name}!</h1>
            <p>
              You can join this community by clicking the button below!
            </p>
            <button className='community-join-button'>Join Community</button>
          </div>
        ) : 
        <div>
          <h1>Whoops!</h1>
          <h3>Looks like you aren't a member of this chat.</h3>
        </div>
        }
      </div>
    );
  }
  const handleUserClick = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  return (
    <div className="chat">
      <div className="messageInteractions">
        <div className="messages">
          <ul>
            {messagesMap[chatId] && messagesMap[chatId].length > 0 ? (
              messagesMap[chatId].map((message, index) => {
                const showUserInfo =
                  index === 0 ||
                  messagesMap[chatId][index - 1].sender !== message.sender ||
                  message.timestamp - messagesMap[chatId][index - 1].timestamp > 600000;
                
                return (
                  <li
                    key={message.id}
                    onMouseEnter={() => setHoveredMessage(message.id)}
                    onMouseLeave={() => setHoveredMessage(null)}
                    className={message.sending ? 'sending-message' : ''}
                  >
                    <div className="message">
                      {showUserInfo && (
                        <div className='message-pfp'>
                          <img
                            className="message-pfp"
                            src={message.senderPhotoURL}
                            alt={`${message.senderUsername}'s avatar`}
                          />
                        </div>
                      )}
                      <div className='message-main'>
                        {showUserInfo && (
                          <div>
                            <p className="message-info" onClick={() => {handleUserClick(message.sender); console.log(message.sender)}}>
                              <strong>{message.senderUsername}</strong>{' '}
                              <span className="timestamp">
                                {formatTimestamp(message.timestamp)}
                              </span>
                            </p>
                          </div>
                        )}
                        <p
                          className={`message-content ${
                            showUserInfo ? '' : 'continued-message'
                          }${message.text.includes(`@${profile.username || profile.displayname}`) ? 'highlight' : ''}`}
                        >
                          {message.text}
                        </p>
                      </div>
                      {hoveredMessage === message.id && (
                        <div className="message-context-menu">
                          <Tooltip text="Reply">
                            <button onClick={() => handleReplyMessage(message)}>
                              <p><span className="material-symbols-outlined">reply</span></p>
                            </button>
                          </Tooltip>
                          {(message.sender === profile.uid || chatOwner === profile.uid) && (
                            <Tooltip text="Delete">
                              <button onClick={() => handleDeleteMessage(message.id)}>
                                <p><span className="material-symbols-outlined msg-delete-button">delete</span></p>
                              </button>
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </div>
                  </li>
                );
              })
            ) : (
              <li>
                <h3>This is the start of this chat.</h3>
                <p>Be the first to send a message!</p>
              </li>
            )}
          </ul>
        </div>
        <div className="message-bar">
          <input
            type="text"
            placeholder="Type a message..."
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Tooltip text="Send">
            <button onClick={handleSendMessage}>
              <p><span className="material-symbols-outlined">send</span></p>
            </button>
          </Tooltip>
        </div>
      </div>
      {showModal && selectedUser && (
        <ProfileModal profile={selectedUser} onClose={closeModal} />
      )}
    </div>
  );
}

export default Chat;