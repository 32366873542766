import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { ref, get, update } from 'firebase/database';
import { db, uploadProfilePicture } from '../firebase'; // Import uploadProfilePicture function
import { fetchUserIdFromToken, updatedisplayname, updateUsername, isValidUserName } from '../utils';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../components/tooltip';

function UserProfilePage() {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState('');
  const [username, setUsername] = useState('');
  const [displayname, setdisplayname] = useState('');
  const [bio, setBio] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [displaynameError, setdisplaynameError] = useState('');
  const [bioError, setBioError] = useState('');
  const [file, setFile] = useState(null); // State for file input
  const [uploading, setUploading] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['userToken']);
  const userToken = cookies.userToken;
  const [color1, setColor1] = useState({ r: 0, g: 0, b: 0 });
const [color2, setColor2] = useState({ r: 0, g: 0, b: 0 });
const [rotation, setRotation] = useState(0);


useEffect(() => {
  const fetchUserProfile = async () => {
    try {
      const userId = await fetchUserIdFromToken(userToken);
      if (!userId) {
        setError('User ID could not be determined');
        return;
      }

      const userRef = ref(db, `users/${userId}`);
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        setProfile(data);
        setUsername(data.username || '');
        setdisplayname(data.displayname || '');
        setBio(data.bio || '');
        // Ensure color and rotation are set, defaulting if not present
        setColor1(data.color1 || { r: 255, g: 255, b: 255 });
        setColor2(data.color2 || { r: 0, g: 0, b: 0 });
        setRotation(data.rotation || 0);
      } else {
        setError('No data available');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
      setError('Error: ' + error.message);
    }
  };

  fetchUserProfile();
}, [userToken, navigate]);

  const validateUsername = (username) => {
    if (username === "") {
      return "Must have a username";
    }
    if (!isValidUserName(username)) {
      return;
    }
    return;
  };

  const validatedisplayname = (displayname) => {
    if (displayname.length < 3 || displayname.length > 20) {
      return 'Display name must be between 3 and 20 characters long';
    }
    return '';
  };

  const handleSave = async () => {
    const newUsernameError = validateUsername(username);
    const newdisplaynameError = validatedisplayname(displayname);
  
    setUsernameError(newUsernameError);
    setdisplaynameError(newdisplaynameError);
  
    if (newUsernameError || newdisplaynameError) {
      return;
    }
  
    try {
      const userId = await fetchUserIdFromToken(userToken);
  
      // Update username, display name, bio, and banner details in Firebase
      await update(ref(db, `users/${userId}`), {
        bio,
        color1,
        color2,
        rotation,
      });
  
      // Re-fetch the updated profile
      const snapshot = await get(ref(db, `users/${userId}`));
      if (snapshot.exists()) {
        const data = snapshot.val();
        setProfile(data);
      }
    } catch (error) {
      setError('Error updating profile: ' + error.message);
    }
  };
  

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (file) {
      setUploading(true);
      try {
        const userId = await fetchUserIdFromToken(userToken);
        const photoURL = await uploadProfilePicture(file, userId);

        // Update the user's profile picture URL
        await update(ref(db, `users/${userId}`), { photoURL });

        // Update the profile state
        setProfile((prevProfile) => ({ ...prevProfile, photoURL }));
        setFile(null); // Clear the file input
      } catch (error) {
        console.error('Error uploading file:', error);
        setError('Error uploading profile picture');
      } finally {
        setUploading(false);
      }
    }
  };

  const signOut = () => {
    removeCookie('userToken', { path: '/' });
    navigate('/login');
  };
  function formatDate(dateString) {
    // Create a new Date object from the date string
    const date = new Date(dateString);

    // Define an array of month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Get the month, day, and year
    let month = months[date.getUTCMonth()]; // Get month name
    let day = date.getUTCDate();
    let year = date.getUTCFullYear();

    // Return the formatted date as "Month Day, Year"
    return `${month} ${day}, ${year}`;
}

  return (
    <div>
      {error && <p style={{ color: 'var(--error-color)' }}>{error}</p>}
      {profile ? (
        <>
          <div className="profile">
            <div className="profile-card">
            <div className="profile-card-banner">
  <div
    className="profile-banner-preview"
    style={{
      background: profile
        ? `linear-gradient(${rotation}deg, rgb(${color1.r}, ${color1.g}, ${color1.b}), rgb(${color2.r}, ${color2.g}, ${color2.b}))`
        : 'linear-gradient(90deg, #ff7e5f, #feb47b)',
      height: '100%',
      width: '100%',
      borderRadius: '8px 8px 0px 0px',
    }}
  >
    <img
      className="profile-card-avatar"
      src={profile.photoURL || '/static/media/user.2ef861692ad966dddf721b4ee81da255.svg'}
      alt={`${profile.displayname}'s avatar`}
    />
  </div>
</div>
              <div className="profile-card-userinfo">
                <div className="profile-card-userinfo-row">
                  <h2 className="profile-card-displayname">{profile.displayname}</h2>
                  <div className="profile-card-badges">
                  {profile.badges ? (
                      <>
                        {profile.badges.includes("staff") && (
                          <Tooltip text={"Flamalite Staff"}><p className='profile-card-badge-staff pcb'><span className="material-symbols-outlined">construction</span></p></Tooltip>
                        )}
                        {profile.badges.includes("betatester") && (
                          <Tooltip text={"Beta Tester"}><p className='profile-card-badge-tester pcb'><span className="material-symbols-outlined">labs</span></p></Tooltip>
                        )}
                        {profile.badges.includes("developer") && (
                          <Tooltip text={"Developer"}><p className='profile-card-badge-developer pcb'><span className="material-symbols-outlined">code</span></p></Tooltip>
                        )}
                        {profile.badges.includes("verified") && (
                          <Tooltip text={"Verified user"}><p className='profile-card-badge-verified pcb'><span className="material-symbols-outlined">verified</span></p></Tooltip>
                        )}
                        {profile.badges.includes("moderator") && (
                          <Tooltip text={"Flamalite Moderator"}><p className='profile-card-badge-moderator pcb'><span className="material-symbols-outlined">shield</span></p></Tooltip>
                        )}
                      </>
                    ) : null}
                  </div>

                </div>
                <h3 className="profile-card-username">{profile.username}</h3>
              </div>
              <div className="profile-card-bio">
                <p>{profile.bio}</p>
              </div>
              <h4 className="profile-card-creation-date-title">
                Member Since
              </h4>
              <p className='profile-card-creation-date'>{formatDate(profile.creationDate)}</p>
              <p className="profile-card-uid">
                UID: {fetchUserIdFromToken(userToken)}
              </p>
            </div>
            <div className="profile-settings">
              <h4>Profile Picture</h4>
              <div className='avatar-setting'>
                <label for="profile-settings-avatar-upload" className='profile-settings-avatar-label'>
                <img
              className="profile-settings-avatar"
              src={profile.photoURL || '/static/media/user.2ef861692ad966dddf721b4ee81da255.svg'}
              alt={`${profile.displayname}'s avatar`}
              ></img>
                </label>
              <input
                type="file"
                accept="image/png, image/jpeg"
                onChange={handleFileChange}
                className='profile-settings-avatar-upload'
                id='profile-settings-avatar-upload'
              />
              </div>
              <button
                className="profile-settings-upload"
                onClick={handleUpload}
                disabled={uploading}
              >
                {uploading ? 'Uploading...' : 'Upload'}
              </button>
              <h4>Display Name</h4>
              <input
                type="text"
                className="profile-setting profile-setting-displayname"
                value={displayname}
                onChange={(e) => setdisplayname(e.target.value)}
              />
              {displaynameError && <p className="profile-settings-error">{displaynameError}</p>}
              <h4>Username</h4>
              <input
                type="text"
                className="profile-setting profile-setting-username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              {usernameError && <p className="profile-settings-error">{usernameError}</p>}
              <h4>About Me</h4>
              <input
                type="text"
                className="profile-setting profile-setting-bio"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              />
              {bioError && <p className="profile-settings-error">{bioError}</p>}
              <h4>Banner Colors</h4>
<div className='banner-selector'>
  <div className='banner-color-1'>
    <label>Red</label>
    <input type="range" min="0" max="255" value={color1.r} onChange={(e) => setColor1({ ...color1, r: e.target.value })} />
    <label>Green</label>
    <input type="range" min="0" max="255" value={color1.g} onChange={(e) => setColor1({ ...color1, g: e.target.value })} />
    <label>Blue</label>
    <input type="range" min="0" max="255" value={color1.b} onChange={(e) => setColor1({ ...color1, b: e.target.value })} />
  </div>

  <div className='banner-color-2'>
    <label>Red</label>
    <input type="range" min="0" max="255" value={color2.r} onChange={(e) => setColor2({ ...color2, r: e.target.value })} />
    <label>Green</label>
    <input type="range" min="0" max="255" value={color2.g} onChange={(e) => setColor2({ ...color2, g: e.target.value })} />
    <label>Blue</label>
    <input type="range" min="0" max="255" value={color2.b} onChange={(e) => setColor2({ ...color2, b: e.target.value })} />
  </div>

  <h4>Banner Rotation</h4>
  <div className='banner-rotation'>
    <input type="range" min="0" max="360" value={rotation} onChange={(e) => setRotation(e.target.value)} />
  </div>

  <div
    className='banner-preview'
    style={{
      background: `linear-gradient(${rotation}deg, rgb(${color1.r}, ${color1.g}, ${color1.b}), rgb(${color2.r}, ${color2.g}, ${color2.b}))`,
      height: '100px',
      width: '100%',
    }}
  />
</div>

              <button className="profile-settings-save" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
          <div>
            <hr />
            <button className="signout-button" onClick={signOut}>
              Sign Out
            </button>
          </div>
        </>
      ) : (
        <p>Loading profile...</p>
      )}
    </div>
  );
}

export default UserProfilePage;
