import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebase';
import { ref, onValue, off } from 'firebase/database';
import { useParams } from 'react-router-dom';
import ProfileModal from './profilepopout';
import { fetchUserIdFromToken } from '../utils';
import { useCookies } from 'react-cookie';
import defaultIcon from '../images/icons/user.svg';

function UserList() {
  const [users, setUsers] = useState([]);
  const [chatName, setChatName] = useState('Undefined');
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isOwnerStatus, setIsOwnerStatus] = useState(false);
  const { chatId } = useParams();
  const [cookies] = useCookies(['userToken']);

  const isUserOnline = (lastOnline) => {
    const currentTime = Date.now();
    return currentTime - lastOnline <= 20000;
  };

  const updateUserInState = useCallback((userId, userData) => {
    setUsers((prevUsers) => {
      const existingUserIndex = prevUsers.findIndex((u) => u.uid === userId);
      const updatedUser = { uid: userId, ...userData };

      if (existingUserIndex !== -1) {
        const updatedUsers = [...prevUsers];
        updatedUsers[existingUserIndex] = updatedUser;
        return updatedUsers;
      } else {
        return [...prevUsers, updatedUser];
      }
    });
  }, []);

  useEffect(() => {
    const chatRef = ref(db, `chats/${chatId}`);
    let unsubscribeChat;
    let unsubscribeOwner = null;
    const unsubscribeMembers = {};

    const clearListeners = () => {
      if (unsubscribeChat) unsubscribeChat();
      if (unsubscribeOwner) unsubscribeOwner();
      Object.values(unsubscribeMembers).forEach(unsubscribe => unsubscribe());
    };

    unsubscribeChat = onValue(chatRef, (snapshot) => {
      const chatData = snapshot.val();
      if (!chatData) return;

      const { owner: ownerId, members = [], name = 'Undefined' } = chatData;

      setChatName(name);
      setUsers([]); // Reset users when chat changes

      const userID = fetchUserIdFromToken(cookies.userToken);
      setIsOwnerStatus(userID === ownerId);

      if (ownerId) {
        const ownerRef = ref(db, `users/${ownerId}`);
        unsubscribeOwner = onValue(ownerRef, (userSnapshot) => {
          const ownerData = userSnapshot.val();
          updateUserInState(ownerId, { ...ownerData, isOwner: true });
        });
      }

      members.forEach((userId) => {
        const userRef = ref(db, `users/${userId}`);
        unsubscribeMembers[userId] = onValue(userRef, (userSnapshot) => {
          const userData = userSnapshot.val();
          updateUserInState(userId, userData);
        });
      });
    });

    return clearListeners;
  }, [chatId, cookies.userToken, updateUserInState]);

  const sortedUsers = users.sort((a, b) => {
    const aIsOnline = isUserOnline(a.lastOnline);
    const bIsOnline = isUserOnline(b.lastOnline);
    if (aIsOnline !== bIsOnline) return aIsOnline ? -1 : 1;
    return a.displayname.localeCompare(b.displayname);
  });

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  return (
    <div className="user-list">
      <div className='user-list-top'>
        {chatId ? 
          <h3 className='user-list-chat-name'>{chatName}</h3>
          :
          <h2 className='user-list-chat-name'>Settings</h2>
        }
        {chatId ? 
          <p className='user-list-member-count'>
            Members: {users.length}
          </p>
          : 
          <p className='user-list-member-count'></p>
        }
      </div>
      <ul className="user-list-items">
        <li className='user-list-item'>
          {chatId && isOwnerStatus ?
            <button className='user-list-add-user'>Add user</button>
            :
            <div></div>
          }
        </li>
        {sortedUsers.map((user) => (
          <li key={user.uid} className={`user-list-item ${isUserOnline(user.lastOnline) ? 'online' : 'offline'}`} onClick={() => handleUserClick(user)}>
            {/* Status circle */}
            <img 
              src={user.photoURL || defaultIcon} 
              alt={user.username}
              className="user-avatar"
            />
            <span
              className={`status-circle`}
            ></span>
            <span className={`user-list-username username ${user.isOwner ? 'owner' : ''}`}>
              {user.displayname} {user.isOwner && '[ Owner ]'}
            </span>
          </li>
        ))}
      </ul>
      {showModal && selectedUser && (
        <ProfileModal profile={selectedUser} onClose={closeModal} />
      )}
    </div>
  );
}

export default UserList;
