import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { fetchUserIdFromToken } from '../utils';

function Navbar() {
    const [cookies] = useCookies(['userToken']);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            const userId = await fetchUserIdFromToken(cookies.userToken);
            setIsAuthenticated(!!userId);
        };

        checkAuth();
    }, [cookies.userToken, navigate]);

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <Link to="/" className="navbar-logo">
                    <img src='/flamalite.png' className='icon' alt="Flamalite Logo" />
                    <p>FLAMALITE</p>
                </Link>
                <div className="navbar-buttons">
                    {isAuthenticated ? (
                        <Link to="/app" className="btn btn-go-to-app">GO TO APP</Link>
                    ) : (
                        <>
                            <Link to="/login" className="btn btn-login">LOGIN</Link>
                            <Link to="/signup" className="btn btn-signup">CREATE AN ACCOUNT</Link>
                        </>
                    )}
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
