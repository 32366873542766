import { ref, set, update } from "firebase/database";
import { getAuth } from "firebase/auth";
import { db } from "./firebase";
import { fetchUserIdFromToken } from "./utils";

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

const updateOnlineStatus = () => {
    console.log('Updating status')
  const user = fetchUserIdFromToken(getCookie('userToken'))
  console.log(user)
  if (user) {
    const userRef = ref(db, `users/${user}/lastOnline`);
    const timestamp = Date.now();
      set(userRef, timestamp);
    setInterval(() => {
      const timestamp = Date.now();
      set(userRef, timestamp);
    }, 10000);
  }
};
updateOnlineStatus()