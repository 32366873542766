import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useCookies } from 'react-cookie';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { fetchUserIdFromToken } from '../utils';

function SignInPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [cookies, setCookie] = useCookies(['userToken']);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const userId = await fetchUserIdFromToken(cookies.userToken);
      if (userId) {
        console.log('user already logged in');
        navigate('/app');
      } else {
        console.log("user not logged in");
      }
    };

    checkAuth();
  }, [cookies.userToken, navigate]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Store user token in cookies
      setCookie('userToken', await user.getIdToken(), { path: '/' });
      
      // Redirect to UserProfilePage
      navigate('/app');
    } catch (error) {
      console.error('SignIn error:', error);
      if (error.code === "auth/invalid-credential") {
        setError('Invalid email or password')
      } else {
        setError("An unknown error occurred");
      }
    }
  };

  return (
    <div className="formContainer">
      <div className='form'>
      <h1>Sign In</h1>
      <form onSubmit={handleSignIn}>
      {error && <p className="errorMessage" style={{ visibility: error ? 'visible' : 'hidden' }}>{error}</p>}
        <div className="formInput emailInput">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="formInput passwordInput">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="formSubmit">Sign In</button>
        <p className="formLink">Need an account? <a href="/signup">Sign up</a></p>
      </form>
      </div>
    </div>
  );
}

export default SignInPage;
