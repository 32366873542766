import React from 'react';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';

function HomePage() {
    return (
        <div className="homepage">
            <Navbar />
            <header className="hero">
                <div className="hero-content">
                    <h1>Welcome to Flamalite</h1>
                    <p>Your gateway to seamless communication and innovative features.</p>
                    <Link to="/signup" className="btn btn-primary">Get Started</Link>
                </div>
            </header>
            <section className="features">
                <h2>Features</h2>
                <div className="feature-cards">
                    <div className="feature-card">
                        <h3>Real-time Chat</h3>
                        <p>Experience instant messaging with our real-time chat feature.</p>
                    </div>
                    <div className="feature-card">
                        <h3>Customizable Profiles</h3>
                        <p>Personalize your profile with custom images and details.</p>
                    </div>
                    <div className="feature-card">
                        <h3>Secure and Private</h3>
                        <p>Ensure your communications are secure with advanced privacy features.</p>
                    </div>
                </div>
            </section>
            <section className="about">
                <h2>About Us</h2>
                <p>Flamalite is dedicated to providing the best communication experience with a focus on security and customization.</p>
                <Link to="/about" className="btn btn-secondary">Learn More</Link>
            </section>
            <footer className="footer">
                <div className="footer-content">
                    <p>&copy; 2024 Flamalite. All rights reserved.</p>
                    <div className="footer-links">
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        <Link to="/terms">Terms of Service</Link>
                        <Link to="/contact">Contact Us</Link>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default HomePage;
