import React, { useState, useEffect } from "react";
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from "firebase/auth";
import { useCookies } from "react-cookie";
import { ref, set } from "firebase/database";
import { auth, db, isUsernameUnique, createUser } from "../firebase";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchUserIdFromToken,
  isValidUserName,
  isValidPassword,
} from "../utils";

function SignupPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const [birthday, setBirthday] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [error, setError] = useState();
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [birthdayError, setBirthdayError] = useState(false);
  const [cookies, setCookie] = useCookies(["userToken"]);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const userId = await fetchUserIdFromToken(cookies.userToken);
      if (userId) {
        navigate("/app");
      }
    };

    checkAuth();
  }, [cookies.userToken, navigate]);

  const validatePassword = (updated) => {
    if (updated === "") {
      setPasswordError(false);
      return;
    }
    if (!isValidPassword(updated)) {
      setPasswordError(true);
      return;
    }
    setPasswordError(false);
    return;
  };

  const validateConfirmPassword = (updated) => {
    if (updated === "") {
      setConfirmPasswordError(false);
      return;
    }
    if (updated!== password) {
      setConfirmPasswordError(true);
      return;
    }
    setConfirmPasswordError(false);
    return;
  }

  const validateUsername = async (updated) => {
    if (updated === "") {
      setUsernameError(false);
      return;
    }
    const validUsername = await isValidUserName(updated);
    if (!validUsername) {
      setUsernameError(true);
      return;
    }
    setUsernameError(false);
    return;
  };

  const validateEmail = async (updated) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (updated === "") {
      setEmailError(false);
      return;
    }
    if (!emailRegex.test(updated)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
    return;
  }

  const validateBirthday = async (updated) => {
    if (updated === "") {
      setBirthdayError(false);
      return;
    }
    const today = new Date();
    const birthDate = new Date(updated);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (
      age < 13 ||
      (age === 13 &&
        (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)))
    ) {
      setBirthdayError(true);
      return;
    }
    setBirthdayError(false);
    return;
  }


  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    createUser(email, password, username, birthday, (success) => {
      if (success) {
        navigate("/app");
      } else {
        setError("An error occurred while creating the user");
      }
    });
  };
  

  return (
    <div className="formContainer">
      <div className="form">
        <h1>Sign Up</h1>
        <form onSubmit={handleSignup}>
          <div className="formInput usernameInput">
            <label>Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => {setUsername(e.target.value);validateUsername(e.target.value);}}
              required
              className={usernameError ? 'input-error' : username==="" ? 'input-default' : 'input-valid'}
            />
          </div>
          <div className="formInput emailInput">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => {setEmail(e.target.value);validateEmail(e.target.value);}}
              required
              className={emailError ? 'input-error' : email==="" ? 'input-default' : 'input-valid'}
            />
          </div>
          <div className="formInput passwordInput">
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => {setPassword(e.target.value);validatePassword(e.target.value);}}
              required
              className={passwordError ? 'input-error' : password==="" ? 'input-default' : 'input-valid'}
            />
          </div>
          <div className="formInput confirmPasswordInput">
            <label>Confirm Password:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => {setConfirmPassword(e.target.value);validateConfirmPassword(e.target.value);}}
              className={confirmPasswordError ? 'input-error' : confirmPassword==="" ? 'input-default' : 'input-valid'}
              required
            />
          </div>
          <div className="formInput birthdayInput">
            <label>Birthday:</label>
            <input
              type="date"
              value={birthday}
              onChange={(e) => {setBirthday(e.target.value);validateBirthday(e.target.value);}}
              className={birthdayError ? 'input-error' : birthday==="" ? 'input-default' : 'input-valid'}
              required
            />
          </div>
          <div className="termsInput">
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              required
              className="formCheckbox"
            />
            <label>
              I accept the <Link to={'/terms'}>Terms of Service</Link>
            </label>
          </div>
          <button type="submit" className="formSubmit"
          disabled={!termsAccepted || emailError || passwordError || confirmPasswordError || birthdayError || usernameError || username==="" || email==="" || password==="" || confirmPassword==="" || birthday===""}>
            Sign Up
          </button>
          <p className="formLink">
            Already have an account? <a href="/login">Sign In</a>
          </p>
        </form>
      </div>
    </div>
  );
}

export default SignupPage;
