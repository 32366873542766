import { initializeApp } from 'firebase/app';
import { getAuth, fetchSignInMethodsForEmail, createUserWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, set, get, onValue, push } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import Cookies from 'js-cookie';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCT_hv9mqBkdqF6xag__yajm6ckMnsnPLI",
  authDomain: "flamalitecomms.firebaseapp.com",
  databaseURL: "https://flamalitecomms-default-rtdb.firebaseio.com",
  projectId: "flamalitecomms",
  storageBucket: "flamalitecomms.appspot.com",
  messagingSenderId: "767801876583",
  appId: "1:767801876583:web:d8c09bab137d1e109c34c2",
  measurementId: "G-H9DB6KBTTH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Function to upload a profile picture
// Function to upload a profile picture
export const uploadProfilePicture = async (file, userId) => {
  if (!file) throw new Error('No file provided');
  
  // Create a storage reference
  const fileRef = storageRef(getStorage(), `profile_pictures/${userId}/${file.name}`);
  
  try {
    // Upload file to Firebase Storage
    await uploadBytes(fileRef, file);
    
    // Get the download URL
    const downloadURL = await getDownloadURL(fileRef);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};


// Function to check if username is unique
export const isUsernameUnique = async (username) => {
  if (typeof username !== 'string' || username.trim() === '') {
    throw new Error('Invalid username');
    console.log('Invalid username');
  }
  
  try {
    const dbRef = ref(db, 'users');
    const snapshot = await get(dbRef);
    if (!snapshot.exists()) {
      console.log("Couldnt load database")
      return false;
    }
    const users = snapshot.val();
    for (const uid in users) {
      if (users[uid].username.toLowerCase() === username.toLowerCase()) {
        return false;
      }
    }
    return true;
  } catch (error) {
    console.error('Error checking username uniqueness:', error);
    return false;
  }
};

// Function to update user profile
export const updateUserProfile = async (userId, newProfileData) => {
  try {
    await set(ref(db, 'users/' + userId), newProfileData);
    console.log('User profile updated successfully');
  } catch (error) {
    console.error('Error updating profile:', error);
  }
};

export function fetchMessages(chatId, callback) {
  const messagesRef = ref(db, `chats/${chatId}/messages`);
  onValue(messagesRef, (snapshot) => {
    const messagesArray = [];
    snapshot.forEach((childSnapshot) => {
      const message = childSnapshot.val();
      message.id = childSnapshot.key;
      messagesArray.push(message);
    });

    // Log the retrieved messages to check data

    callback(messagesArray);
  });
}

function createChat(chatName, usersArray) {
  const chatsRef = ref(db, "chats");

  const newChat = {
    name: chatName,
    users: usersArray,
    messages: [],
  };

  push(chatsRef, newChat);
}

// Function to listen to user profile changes in real-time
export const listenToUserProfile = (userId, callback) => {
  const userRef = ref(db, 'users/' + userId);
  onValue(userRef, (snapshot) => {
    if (snapshot.exists()) {
      callback(snapshot.val());
    } else {
      console.log('No data available');
    }
  });
};

export const createUser = async (email, password, username, birthday, callback) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const uid = user.uid;

    // If a profile picture was uploaded, store it in Firebase Storage
    let photoURL = "https://i.sstatic.net/SE2cv.jpg"; // Default photo URL

    // Store user data in the Realtime Database
    await set(ref(db, "users/" + uid), {
      username,
      displayname: username,
      creationDate: new Date().toISOString(),
      bio: "",
      birthday,
      email,
      photoURL,
      setStatus: "online",
      status: true,
      notificationSettings: 0,
      friends: [],
      blockedUsers: [],
      badges: ["betatester"],
    });

    // Retrieve the user token asynchronously
    const idToken = await user.getIdToken();
    
    // Set the user token in cookies
    Cookies.set("userToken", idToken, { expires: 7, secure: true, sameSite: 'Strict' });

    callback(true);
  } catch (error) {
    console.error('Error creating user:', error);
    callback(false);
  }
};


export const isEmailUsed = async (email) => {
  try {
    const signInMethods = await fetchSignInMethodsForEmail(auth, email);
    return signInMethods.length > 0;
  } catch (error) {
    return false;
  }
}

export { auth, db, storage };
