import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { ref, onValue } from "firebase/database";
import { db } from "../firebase";  // Make sure the path is correct
import Navbar from "../components/Navbar";
import Chat from "../components/chat";
import UserProfilePage from "./ProfilePage";
import { fetchTokenFromCookies, fetchUserIdFromCookies, fetchUserIdFromToken, fetchUsernameFromID, fetchdisplaynameFromID, fetchBioFromID, updatedisplayname, updateUsername, isUserLoggedIn } from '../utils';
import { useCookies } from 'react-cookie';
import addCircleIcon from '../images/icons/add_circle.svg';
import Tooltip from "../components/tooltip";
import ChatList from "../components/chatslist";
import LoadingScreen from '../components/LoadingScreen';
import userIcon from '../images/icons/user.svg'; // Add the path to your user icon
import settingsIcon from '../images/icons/settings.svg'; // Add the path to your settings icon
import UserList from "../components/userlist";
import '../status.js'
function FlamaliteApp() {
  const [cookies] = useCookies(['userToken']);
  const navigate = useNavigate();
  const { chatId } = useParams();
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({}); // State to hold user data

  useEffect(() => {
    const loadObjects = async () => {
      await new Promise(resolve => setTimeout(resolve, 2000)); 

      const minimumLoadingTime = 3000; 
      const loadingEndTime = Date.now() + minimumLoadingTime;
      const timeUntilMinimum = loadingEndTime - Date.now();
      if (timeUntilMinimum > 0) {
        setTimeout(() => setLoading(false), timeUntilMinimum);
      } else {
        setLoading(false);
      }
    };

    loadObjects();

    const checkAuth = async () => {
      try {
        const userId = await fetchUserIdFromToken(cookies.userToken);
        if (userId) {
          fetchChats(); 
          fetchUserData(userId); // Fetch user data
        } else {
          console.log("User not logged in");
          navigate('/login');
        }
      } catch (error) {
        console.error("Error checking authentication", error);
        navigate('/login');
      }
    };

    const fetchChats = () => {
      const chatsRef = ref(db, "chats");
      const unsubscribe = onValue(chatsRef, (snapshot) => {
        const data = snapshot.val();
        const chatsArray = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
        setChats(chatsArray);
      });

      return () => unsubscribe();
    };

    const fetchUserData = async (userId) => {
      try {
        const username = await fetchUsernameFromID(userId);
        const displayname = await fetchdisplaynameFromID(userId);
        const bio = await fetchBioFromID(userId);
        setUser({ username, displayname, bio });
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };

    checkAuth();
  }, [cookies.userToken, navigate]);

  return (
    <div>
      <div className="app-mount">
        <div className="app">
          {loading ? <LoadingScreen /> : <div></div>}
          <div className="app-content">
            <div className="layer-modal"></div>
            <div className="layer-tooltip"></div>
            <div className="layer-content">
              <div className="left">
                <div className="user-pannel">
                <img className="user-pannel-icon" src={user.photoURL || userIcon} alt="User Icon" />

                  <div className="user-pannel-info">
                    <p className="user-pannel-name">{user.displayname || user.username}</p>
                    <p className="user-pannel-status">Status: {"Online"}</p>
                  </div>
                  <div className="user-pannel-controls">
                    <button className="user-pannel-button settings-button">
                      <Link to='/app'><img src={settingsIcon} alt="Settings" /></Link>
                    </button>
                  </div>
                </div>
                <div className="chats">
                  <ChatList chats={chats} />
                </div>
              </div>
              <div className="center">
                {chatId ? <Chat chatId={chatId} /> : <UserProfilePage />}
              </div>
              <div className="right">
                <UserList></UserList>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlamaliteApp;