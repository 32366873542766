import React from 'react';
import Navbar from '../components/Navbar';

const TermsOfService = () => {
  return (
    <div>
        <Navbar></Navbar>
        <div className='terms-of-service'>
      <h1>Terms of Service</h1>
      <p className='tos-effective'>Effective: September 6, 2024</p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using the Flamalite App (the "Service"), you agree to be
        bound by these Terms of Service ("Terms"). If you do not agree to all of
        these Terms, please do not use the Service.
      </p>

      <h2>2. Eligibility</h2>
      <p>
        You must be at least 13 years old to use the Service. By using the
        Service, you represent and warrant that you meet this eligibility
        requirement.
      </p>

      <h2>3. Account Registration</h2>
      <p>
        When creating an account, you must provide accurate and complete
        information. You are responsible for safeguarding your password and any
        activities that occur under your account.
      </p>

      <h2>4. User Conduct</h2>
      <p>
        You agree not to engage in any illegal or prohibited activity, including
        but not limited to harassment, impersonation, or spamming. We reserve
        the right to terminate accounts that violate these guidelines.
      </p>

      <h2>5. Content Ownership</h2>
      <p>
        You retain ownership of the content you create and post. However, by
        posting content, you grant us a license to use, modify, and distribute
        your content within the Service.
      </p>

      <h2>6. Termination</h2>
      <p>
        We reserve the right to suspend or terminate your account at any time
        for any reason, including violation of these Terms.
      </p>

      <h2>7. Limitation of Liability</h2>
      <p>
        The Service is provided "as is" without warranties of any kind. We are
        not liable for any damages or losses resulting from your use of the
        Service.
      </p>

      <h2>8. Changes to Terms</h2>
      <p>
        We may modify these Terms at any time. Your continued use of the Service
        constitutes acceptance of any such changes.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at
        support@flamaliteapp.com.
      </p>
    </div>
    </div>
  );
};

export default TermsOfService;
