import React from 'react';

const LoadingScreen = () => {
  return (
    <div className='loading-screen'>
      <svg className="loading-screen-logo" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="white">
        <path className="outline" d="M427-76.3 153.78-233.35q-24.65-14.39-38.82-38.61-14.18-24.21-14.18-53v-310.08q0-28.79 14.18-53 14.17-24.22 38.82-38.61L427-883.7q24.65-14.39 53-14.39t53 14.39l273.22 157.05q24.65 14.39 38.82 38.61 14.18 24.21 14.18 53v310.08q0 28.79-14.18 53-14.17 24.22-38.82 38.61L533-76.3q-24.65 14.39-53 14.39T427-76.3Zm2.83-375.05v254.22L480-167.91l50.17-29.22v-254.22l223.05-129.39v-53.3l-53.74-31.22L480-537.87 260.52-665.26l-53.74 31.22v53.3l223.05 129.39Z" fill="transparent" stroke="white" strokeWidth="2"/>
      </svg>
      <svg className="loading-screen-text" width="500" height="100" xmlns="http://www.w3.org/2000/svg">
        <text className="flamalite-text" x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fontFamily="Arial, sans-serif" fontSize="48" fill="transparent" stroke="white" strokeWidth="1">FLAMALITE</text>
      </svg>
    </div>
  );
};

export default LoadingScreen;
