import React, { useEffect } from 'react';
import defaultIcon from '../images/icons/user.svg'; // Import your default icon
import Tooltip from './tooltip';

function ProfileModal({ profile, onClose }) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.className.includes('modal-overlay')) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  function formatDate(dateString) {
    // Create a new Date object from the date string
    const date = new Date(dateString);

    // Define an array of month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Get the month, day, and year
    let month = months[date.getUTCMonth()]; // Get month name
    let day = date.getUTCDate();
    let year = date.getUTCFullYear();

    // Return the formatted date as "Month Day, Year"
    return `${month} ${day}, ${year}`;
  }

  // Function to get banner styles
  const getBannerStyles = () => {
    const { color1, color2, rotation } = profile;
    console.log(color1, color2, rotation);
  
    // Check if color1 and color2 are valid and have r, g, b properties
    const hasColors = color1 && color2 && rotation !== undefined;
    const defaultGradient = 'linear-gradient(45deg, #4a90e2, #50e3c2)';
  
    const gradient = hasColors 
      ? `linear-gradient(${rotation}deg, rgb(${color1.r}, ${color1.g}, ${color1.b}), rgb(${color2.r}, ${color2.g}, ${color2.b}))` 
      : defaultGradient;
  
    console.log(gradient);
  
    return {
      background: gradient,
      height: '150px', // Adjust as needed
      width: '100%',
      borderRadius: '8px',
    };
  };
  

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="profile-card-modal">
          <button className="modal-close" onClick={onClose}><span class="material-symbols-outlined">
close
</span></button>
          <div className="profile-card-banner" style={getBannerStyles()}>
            <img
              className="profile-card-avatar"
              src={profile.photoURL || defaultIcon}
              alt={`${profile.username}'s avatar`}
            />
          </div>
          <div className="profile-card-userinfo">
            <div className="profile-card-userinfo-row">
              <h2 className="profile-card-displayname">{profile.displayname}</h2>
              <div className="profile-card-badges">
                {profile.badges ? (
                  <>
                    {profile.badges.includes("staff") && (
                      <Tooltip text={"Flamalite Staff"}>
                        <p className='profile-card-badge-staff pcb'>
                          <span className="material-symbols-outlined">construction</span>
                        </p>
                      </Tooltip>
                    )}
                    {profile.badges.includes("betatester") && (
                      <Tooltip text={"Beta Tester"}>
                        <p className='profile-card-badge-tester pcb'>
                          <span className="material-symbols-outlined">labs</span>
                        </p>
                      </Tooltip>
                    )}
                    {profile.badges.includes("developer") && (
                      <Tooltip text={"Developer"}>
                        <p className='profile-card-badge-developer pcb'>
                          <span className="material-symbols-outlined">code</span>
                        </p>
                      </Tooltip>
                    )}
                    {profile.badges.includes("verified") && (
                      <Tooltip text={"Verified user"}>
                        <p className='profile-card-badge-verified pcb'>
                          <span className="material-symbols-outlined">verified</span>
                        </p>
                      </Tooltip>
                    )}
                    {profile.badges.includes("moderator") && (
                      <Tooltip text={"Flamalite Moderator"}>
                        <p className='profile-card-badge-moderator pcb'>
                          <span className="material-symbols-outlined">shield</span>
                        </p>
                      </Tooltip>
                    )}
                  </>
                ) : null}
              </div>
            </div>
            <h3 className="profile-card-username">{profile.username}</h3>
          </div>
          <div className="profile-card-bio">
            <p>{profile.bio}</p>
          </div>
          <h4 className="profile-card-creation-date-title">
            Member Since
          </h4>
          <p className='profile-card-creation-date'>{formatDate(profile.creationDate)}</p>
          <p className="profile-card-uid">
            UID: {profile.uid}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProfileModal;
