import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import NotFoundPage from './pages/status404';
import FlamaliteApp from './pages/FlamaliteApp';
import TermsOfService from './pages/Terms';
import './App.css';
import './styles/importComponentStyles';


function App() {
  return (
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/app" element={<FlamaliteApp />} />
        <Route path="/app/:chatId" element={<FlamaliteApp />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
  );
}

export default App;
